<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="供应商" prop="name">
          <el-input placeholder="供应商" v-model="params.name" />
        </el-form-item>

        <el-form-item label="联系人" prop="linkMan">
          <el-input placeholder="联系人" v-model="params.linkMan" />
        </el-form-item>

        <el-form-item label="联系人电话" prop="linkPhone">
          <el-input placeholder="联系人电话" v-model="params.linkPhone" />
        </el-form-item>

        <el-form-item label="供应商来源" prop="source">
          <el-select v-model="params.source" clearable>
            <el-option
              v-for="item in resourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="供应商"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系电话"
        ></el-table-column>
        <!-- <el-table-column show-overflow-tooltip label="来源">
          <template slot-scope="{ row }">
            <span>{{ row.source == 1 ? '自主创建' : '外部系统' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column show-overflow-tooltip label="商品自动上架">
          <template slot-scope="{ row }">
            <span>{{ row.automaticShelves == 1 ? '开启' : '关闭' }}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip label="供货状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.state == 1 ? 'green' : 'red' }">{{
              row.state == 1 ? '供货中' : '停用'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'middleman:updateSupplyInfo'"
              type="text"
              @click="goodsManage(row)"
              >货源管理</el-button
            >
            <el-button
              v-auth="
                `${row.state == 1 ? 'middleman:close' : 'middleman:recovery'}`
              "
              @click="operate(row)"
              type="text"
              >{{ row.state == 1 ? '停用' : '恢复供货' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <GoodsManageModal
      v-if="goodsManageVisible"
      @getData="getData(true)"
      :visible.sync="goodsManageVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import GoodsManageModal from './components/GoodsManageModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      goodsManageVisible: false,
      tableData: [],
      resourceList: [
        {
          label: '自主创建',
          value: 1
        },
        {
          label: '已有供应商添加',
          value: 2
        }
      ],
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    GoodsManageModal
  },
  created() {
    this.getData()
  },
  methods: {
    goodsManage(row) {
      this.currentItem = row
      this.goodsManageVisible = true
    },

    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.state == 1 ? '停用' : '恢复供货'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.supplierMaintenance.save({
            relationId: row.relationId,
            status: row.state == 1 ? 2 : 1
          })
          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.middleman.pageSupplier(sendData)
        const tableData = res.page.list.map(item => {
          return item
        })

        this.tableData = tableData
        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
