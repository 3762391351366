<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="货源管理"
    :visible.sync="show"
    width="550px"
  >
    <el-form size="small" ref="form" label-width="140px">
      <el-form-item label="商品自动上架" prop="id">
        <el-switch
          v-model="automaticShelves"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </el-form-item>

      <el-form-item v-if="automaticShelves" label="商品自动加价">
        <el-input
          maxlength="4"
          @blur="blurHandler"
          @input="val => (markUpValue = val.replace(/[^0-9.]/g, ''))"
          style="width: 70px"
          v-model="markUpValue"
        ></el-input
        >&nbsp;&nbsp;%
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      automaticShelves: false,
      markUpValue: 0
    }
  },
  async created() {
    this.automaticShelves =
      this.currentItem.automaticShelves == 1 ? true : false

    this.markUpValue = this.currentItem.markUpValue
  },

  methods: {
    blurHandler() {
      if (+this.markUpValue > 1000) {
        this.markUpValue = 1000
      }
    },
    async formSubmit() {
      this.loading = true

      try {
        await this.$api.supplierMaintenance.save({
          relationId: this.currentItem.relationId,
          automaticShelves: this.automaticShelves ? 1 : 2,
          markUpValue: this.markUpValue
        })
        this.$message.success('保存成功')
        this.$emit('getData')
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
